@media only screen and (min-width: 600px) {
  .WizardContent {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
  }
}

.App-logo {
  pointer-events: none;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.providers {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.providers img {
  width: 50%;
  -webkit-filter: grayscale(100);
  filter: grayscale(100);
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 10px 0;
}


.providers img:hover {
  -webkit-filter: none;
  filter: none;
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .providers img {
    width: 50%;
  }
  .App-logo {
    width: 50%;
  }
}

@media (max-width: 575px) {
  .providers img {
    width: 50%;
  }
  .App-logo {
    width: 50%;
  }
}

.wizard-card {
  height: 100vh;
}
